import React, {Component}  from "react";
import {
    MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBNavbarBrand
} from "mdbreact";
import Img from "gatsby-image";
import {Link} from "gatsby";

class Menu extends Component {
    state = {
        isOpen: false,
        logo: null
    };
    
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };
    
    render() {
        return (
            <MDBNavbar color="default-color" className="border-bottom border-primary" dark expand="md">
                <MDBNavbarBrand>
                    <Link to="/">
                        <Img className="border-bottom border-primary navbar-logo" fluid={this.props.logo} alt="" />
                    </Link>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={this.toggleCollapse} />
                <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav right>
                        <MDBNavItem className="text-right">
                            <Link activeClassName="active"
                                  className="nav-link Ripple-parent waves-effect waves-light" to="/wie-zijn-wij/">
                                Wie zijn wij
                            </Link>
                        </MDBNavItem>
                        <MDBNavItem className="text-right">
                            <Link activeClassName="active"
                                  className="nav-link Ripple-parent waves-effect waves-light" to="/prijslijst/">
                                Prijslijst
                            </Link>
                        </MDBNavItem>
                        <MDBNavItem className="text-right">
                            <Link activeClassName="active"
                                  className="nav-link Ripple-parent waves-effect waves-light" to="/onze-merken/">
                                Onze merken
                            </Link>
                        </MDBNavItem>
                        <MDBNavItem className="text-right">
                            <Link activeClassName="active"
                                  className="nav-link Ripple-parent waves-effect waves-light" to="/contact/">
                                Contact
                            </Link>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    }
}

export default Menu;