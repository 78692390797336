import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import "./layout.css"
import Menu from "./menu";

const MenuContainer = () => {

    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <div className="default-background">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <Menu logo={data.logo.childImageSharp.fluid}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MenuContainer
